import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";
import { useEffect } from "react";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./form@gen.scss";

const GeneratedResourcesStateOfDigitalOperations2021Form = () => {
  useEffect(() => {
    var sc, d;
    try {
      sc = document.createElement("script");
      sc.src =
        "https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/1e5a0db235308f2d98a2965723b61ced14ea1cf95137ddc62a558856d56cf378/form.js";
      sc.crossOrigin = "anonymous";
      sc.id =
        "fs_1e5a0db235308f2d98a2965723b61ced14ea1cf95137ddc62a558856d56cf378";
      d = document.getElementById("download-form");
      d.appendChild(sc);

      new MutationObserver(function (mutationsList, observer) {
        mutationsList.forEach((mutation) => {
          if (mutation.addedNodes) {
            mutation.addedNodes.forEach((node) => {
              var css = node.querySelector
                ? node.querySelector('link[href*="webforms.css"]')
                : null;
              if (css) {
                observer.disconnect();
                css.addEventListener("load", function () {
                  setTimeout(function () {
                    loop: for (
                      var i = 0;
                      i < document.styleSheets.length;
                      i++
                    ) {
                      var styleSheet = document.styleSheets[i];
                      if (
                        styleSheet.href &&
                        styleSheet.href.includes("webforms.css")
                      ) {
                        var rules = styleSheet.rules;
                        for (var j = 0; j < rules.length; j++) {
                          var rule = rules[j];
                          if (
                            rule.selectorText &&
                            rule.selectorText.includes(".hidden,")
                          ) {
                            var cssText = rule.cssText.replace(
                              ".hidden,",
                              ".fs-webform-container .hidden,"
                            );
                            styleSheet.deleteRule(j);
                            styleSheet.insertRule(cssText, j);
                            break loop;
                          }
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        });
      }).observe(document.body, { childList: true, subtree: true });
    } catch (e) {}

    return () => {
      if (sc && d) d.removeChild(sc);
    };
  }, []);

  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          「デジタルオペレーションの現状」ダウンロード | PagerDuty正規代理店
          DXable
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="このeBookでは、このようなリアルタイムな対応を必要とするタスクの増加が、技術チームにどのような負担を与えているのか、またどのように対応すべきなのかを学びます。フォームにご記入の上、eBookをダウンロードしてください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="このeBookでは、このようなリアルタイムな対応を必要とするタスクの増加が、技術チームにどのような負担を与えているのか、またどのように対応すべきなのかを学びます。フォームにご記入の上、eBookをダウンロードしてください。"
        />

        <meta
          property="og:title"
          content="「デジタルオペレーションの現状」ダウンロード | PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="このeBookでは、このようなリアルタイムな対応を必要とするタスクの増加が、技術チームにどのような負担を与えているのか、またどのように対応すべきなのかを学びます。フォームにご記入の上、eBookをダウンロードしてください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/resources/state-of-digital-operations-2021/form/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/resources/state-of-digital-operations-2021/form/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow=""
        textGreen="資料一覧"
        overview="お役立ち資料や、eBookなどをご紹介します。ぜひダウンロードください。
PagerDuty製品に関するご質問は、[お問い合わせページ](/contact-jp/)よりお気軽にお問い合わせください。
"
      />

      <div className="resourcesFormWrapper">
        <div className="w-full">
          <div className="px-[25px]">
            <h1 className="text-[32px] leading-[44px] tracking-[1.92px] font-bold text-center text-[#4EB346] mb-10">
              デジタルオペレーションの現状
            </h1>
            <p className="text-lg leading-8 text-black tracking-[1.08px] max-w-[1000px] mx-auto text-center mb-[30px] md:mb-20">
              DXが進む今日、さまざまなデジタルシステムからのアラートやインシデントは増え続けています。
              本eBookでは、リアルタイム対応が必要なこうした作業の増加がどのように技術チームに負担を強いているのか、どう対応していくべきかを解説しています。
            </p>
          </div>
          <div className="flex mb-20 flex-col md:flex-row gap-y-10">
            <div className="flex-1 pl-4 lg:pl-0">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/img_b70eab64bc.png?updated_at=2022-12-07T16:44:42.020Z"
                alt="Digital Operation Thank You Page"
                width="350px"
                height="350px"
                classname="w-[250px] h-[250px] mx-auto md:ml-0 md:w-[350px] md:h-[350px]"
              />
            </div>
            <div className="flex-1 md:min-w-[600px]">
              <p className="text-lg leading-8 font-medium px-[36px]">
                ご利用のインターネット環境によりフォームが表示されない場合がございます。
                もしも、入力フォームが表示されない場合は、こちらのメールアドレスへお問い合わせください。
                <a
                  href="mailto:info@digitalstacks.net"
                  className="text-[#0000FF]"
                >
                  info@digitalstacks.net
                </a>
                <br />
                <br />
                <span className="text-[#FD0413]">
                  *この項目は入力する必要があります。
                </span>
              </p>
              <div id="download-form" />
              <p>
                <a
                  target="_blank"
                  href="https://www.digitalstacks.net/privacy-policy"
                  className="text-[#4EB346]"
                >
                  個人情報保護方針
                </a>
                についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedResourcesStateOfDigitalOperations2021Form;
